import supabase from "./supabase";

var MARCADOS =[]
document.addEventListener('DOMContentLoaded', async () => {
    const { data: horariosOcupados, error } = await supabase
        .from('ConnectLive')
        .select('*');
    if (error) {
        console.error('Erro ao buscar horários:', error);
        return;
    }
    console.table(horariosOcupados)
    horariosOcupados.forEach(ocupado => {
      MARCADOS.push(ocupado.Data)
    })
  console.table(MARCADOS)})

  export default MARCADOS