import './App.css';
import luciana from './imagens/luciana sem fundo 1.png'
import Fotos from './fotos';
import MARCADOS from './geramarcados';
import supabase from './supabase';



function App() {
  
  console.table(MARCADOS)   
  const OPCOES = {
    weekday: 'short',
    day:'numeric',
    month:'numeric'
  }

  function dias() {
    const PROXIMOSTRES = [];
    let CONTADOR = 0;
    const HOJE = new Date(); 
    
    while (PROXIMOSTRES.length < 3) {
        const AMANHA = new Date();
        AMANHA.setDate(HOJE.getDate() +1 + CONTADOR++);
        
        // Evita sábado e domingo
        if (AMANHA.getDay() !== 0 && AMANHA.getDay() !== 6) {
            PROXIMOSTRES.push(new Date(AMANHA)); // Adiciona uma cópia da data
        }
    }
    console.log(PROXIMOSTRES)
    return PROXIMOSTRES;
}

  function criarbotaodia(){
    const DIAS = dias()
    const CONTAINER = document.getElementById("dias");
    for (var i = 0; i < DIAS.length; i++){
      let BOTAO_DIA = document.createElement('input')
      BOTAO_DIA.setAttribute('type', 'radio');
      BOTAO_DIA.setAttribute('name', 'dia');    
      BOTAO_DIA.addEventListener('change', CRIAR_BOTAO_HORARIO)
      const DIA_ARRUMADO = DIAS[i].toLocaleDateString('pt-BR', OPCOES)//arruma o dia//
      BOTAO_DIA.setAttribute('id', DIA_ARRUMADO)
      if (i === 0){
        BOTAO_DIA.setAttribute('checked', 'checked')
      }
      CONTAINER.appendChild(BOTAO_DIA)//cria botao
  
      let LABEL_DIA = document.createElement('label');
      LABEL_DIA.setAttribute('for', DIA_ARRUMADO)
      LABEL_DIA.textContent = DIA_ARRUMADO.replace('.,','').toUpperCase()
      CONTAINER.appendChild(LABEL_DIA)

      if(i < 2){
        let DIVISAO = document.createElement('div')
        DIVISAO.setAttribute('class', 'divisao')
        CONTAINER.appendChild(DIVISAO)
      }
    }
    CRIAR_BOTAO_HORARIO()
  }

  function HORAS() {
    const HORAS_ATIVAS = [];
    let HORA = new Date();
    HORA.setHours(8, 30, 0); 
    while (HORA.getHours() < 11 || (HORA.getHours() === 11 && HORA.getMinutes() <= 30)) {
        HORAS_ATIVAS.push(new Date(HORA)); 
        HORA.setMinutes(HORA.getMinutes() + 20); 
    }
    HORA = new Date();
    HORA.setHours(14, 0, 0); 

    while (HORA.getHours() < 16 || (HORA.getHours() === 16 && HORA.getMinutes() === 0)) {
        HORAS_ATIVAS.push(new Date(HORA));  
        HORA.setMinutes(HORA.getMinutes() + 20); 
    }

    return HORAS_ATIVAS;
}

  function ARRUMAR_HORA(){
    const HORAS_ARRUMADAS = []
    let HORAS_BRUTAS = HORAS()
    HORAS_BRUTAS.forEach(function(horario){
      var HORA_ARRUMADA = horario.toLocaleTimeString("pt-BR", {hour:'2-digit', minute:'2-digit'})
      HORAS_ARRUMADAS.push(HORA_ARRUMADA)
    })
    return HORAS_ARRUMADAS
  }

  function CRIAR_BOTAO_HORARIO(){
    let HORAS_ARRUMADAS = ARRUMAR_HORA()
    const CONTAINER = document.getElementById('horarios')
    CONTAINER.innerHTML = ''
    let DIA_SELECIONADO = document.querySelector('input[name="dia"]:checked').id
    HORAS_ARRUMADAS.forEach(hora =>{
      if (MARCADOS.includes(DIA_SELECIONADO + hora) === false){
      let BOTAO_HORARIO = document.createElement('input');
      BOTAO_HORARIO.setAttribute('type', 'radio');
      BOTAO_HORARIO.setAttribute('name', 'horario');
      BOTAO_HORARIO.setAttribute('id', DIA_SELECIONADO + hora);
      if (hora === HORAS_ARRUMADAS[0]){
        BOTAO_HORARIO.setAttribute('checked','checked')
      }
      CONTAINER.appendChild(BOTAO_HORARIO);
     
      let LABEL_HORARIO = document.createElement('label')
      LABEL_HORARIO.setAttribute('for', DIA_SELECIONADO + hora)
      LABEL_HORARIO.textContent = hora.replace(':','h')
      CONTAINER.appendChild(LABEL_HORARIO)
    }
    })
  }
  var emailnaovalido = true
  function validaremail(){
    var email = document.getElementById('Email')
    if (!email.value.match(/^[A-Za-z\._\-0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)){
      email.style.borderColor = 'red'
      email.style.outline = '2px solid red'
      emailnaovalido = true
    } else{
      email.style.outline = '0px'
      emailnaovalido = false
    }
  }
  let NumeroInvalido = true
  function arrumarnumero(){
    const telefoneInput = document.getElementById("Numero");
    let telefone = telefoneInput.value;
    telefone = telefone.replace(/\D/g, "");
    if (telefone.length > 10) {
      telefone = telefone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
      telefoneInput.style.borderColor = 'black'
      telefoneInput.style.outline = 'black'
      NumeroInvalido = false
    } else if (telefone.length > 6) {
      telefone = telefone.replace(/(\d{2})(\d{4})(\d{0,4})/, "($1) $2-$3");
    } else if (telefone.length > 2) {
      telefone = telefone.replace(/(\d{2})(\d{0,5})/, "($1) $2");
    } else {
      telefone = telefone.replace(/(\d*)/, "($1");
      telefoneInput.style.borderColor = 'red'
      telefoneInput.style.outline = 'red'

      }
      telefoneInput.value = telefone;
      ;}
      var jatem = false

  function arrumarinsta(){
    const InstagramInput = document.getElementById('Insta')
    let Insta = InstagramInput.value
    if (Insta.length > 0 && jatem === false){
      Insta = '@' + Insta
      jatem = true
      InstagramInput.style.borderColor = 'black'
    } else if (Insta.length === 0){
      jatem = false
    }
    InstagramInput.value = Insta
    }
  async function salvarsupabase(){
    const INFO = {
      Nome: document.getElementById('Nome').value,
      Email: document.getElementById('Email').value,
      Numero: document.getElementById('Numero').value,
      Instagram: document.getElementById('Insta').value,
      Data: document.querySelector('input[name="horario"]:checked').id
    }
    await supabase
    .from('ConnectLive') // Substitua pelo nome da sua tabela
    .insert([
      INFO,
    ])
    .select()
  }
  function salvarSheets(){
    const data = {
      Nome: document.getElementById('Nome').value,
      Email: document.getElementById('Email').value,
      Numero: document.getElementById('Numero').value,
      Instagram: document.getElementById('Insta').value,
      Data: document.querySelector('input[name="horario"]:checked').id
    }
    fetch("https://api.sheetmonkey.io/form/24jzLHj4qrWpW4gQwpnNbC", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
  function formatarData() {
    const dataString = document.querySelector('input[name = "horario"]:checked').id
    const dataHora = dataString.split(', ')[1];
    const data = dataHora.slice(0, 5); 
    const hora = dataHora.slice(5);  
    const [dia, mes] = data.split('/');
    const [horaFormatada, minuto] = hora.split(':');
    const ano = new Date().getFullYear(); 
    const dataISO = `${ano}-${mes}-${dia}T${horaFormatada}:${minuto}:00`;
    return dataISO;
  }
  function salvarAgenda(){
    const Data = {
      NomeCliente: document.getElementById('Nome').value,
      EmailCliente: document.getElementById('Email').value,
      Comeca: formatarData()
    }
    fetch('https://hook.us2.make.com/oeptia64llbq10ipr4hlrg7ouh5ns22w', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body:JSON.stringify(Data),
    });
  }
  function separarData(dataString) {
    const diasDaSemana = {
      "seg.": "Segunda-feira",
      "ter.": "Terça-feira",
      "qua.": "Quarta-feira",
      "qui.": "Quinta-feira",
      "sex.": "Sexta-feira",
      "sáb.": "Sábado",
      "dom.": "Domingo"
    };
    const meses = {
      "01": "Janeiro",
      "02": "Fevereiro",
      "03": "Março",
      "04": "Abril",
      "05": "Maio",
      "06": "Junho",
      "07": "Julho",
      "08": "Agosto",
      "09": "Setembro",
      "10": "Outubro",
      "11": "Novembro",
      "12": "Dezembro"
    };
    const [diaSemanaAbrev, dataEHora] = dataString.split(/,\s*/);
    const data = dataEHora.slice(0, 5); 
    const horario = dataEHora.slice(5); 
    const [diaMes, mes] = data.split('/');
    return {
      "Dia da semana": diasDaSemana[diaSemanaAbrev],
      "Dia do mês": diaMes,
      "Mês": meses[mes],
      "Horário": horario
    };
  }
  function Mensagem(Numero, Nome, Data){
    const data = separarData(Data)
    const payload = {
      "number":'55'+ Numero.replace(/\D/g, ''),
      "text":'Deu certo seu agendamento,' + Nome + '!!\n '+ data['Dia do mês']+' de '+data['Mês']+' as '+data['Horário']+'.\nTe envio mais detalhes por aqui😃🧑‍⚕️',
      "delay":123
    }
    const options = {
      method: 'POST',
      headers: {apikey: '117EAABB98A5-4D26-8604-B98640194592', 'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    };
    
    fetch('https://evolutionapi.digitalnnect.io/message/sendText/MatheusGrazi', options)
      .then(response => response.json())
      .then(response => console.log(response))
      .catch(err => console.error(err));
    
    const primeiroNome = Nome.split(' ')[0]
    const payloadLuci = {
      "number":'+551197179-0417',
      "text":'Avaliação Agendada 🥳\n'+ primeiroNome + ', '+ data['Dia da semana'] + ' as '+data['Horário'],
      "delay":123
    }
    const optionsLuci = {
      method: 'POST',
      headers: {apikey: '117EAABB98A5-4D26-8604-B98640194592', 'Content-Type': 'application/json'},
      body: JSON.stringify(payloadLuci)
    };
    
    fetch('https://evolutionapi.digitalnnect.io/message/sendText/MatheusGrazi', optionsLuci)
      .then(response => response.json())
      .then(response => console.log(response))
      .catch(err => console.error(err));
  }

  function Scroll(){
   const containerdias = document.getElementById('dias')
   const botaoesquerda = document.getElementById('esquerda')
   const botaodireita = document.getElementById('direita')
   const largura = window.innerWidth
   const scrollLeftPosition = containerdias.scrollLeft;
   if (scrollLeftPosition < -300 && largura <500) {
    botaoesquerda.style.visibility = 'hidden'; 
    botaodireita.style.visibility = 'visible'; 
  } else if (scrollLeftPosition < 10 && largura > 500) {
    botaoesquerda.style.visibility = 'hidden'; 
    botaodireita.style.visibility = 'visible'; }
  else if (scrollLeftPosition >= 300 && largura < 500 ) {
    botaoesquerda.style.visibility = 'visible'; 
    botaodireita.style.visibility = 'hidden'; 
  } else if(scrollLeftPosition >=200 && largura > 500){
    botaoesquerda.style.visibility = 'visible'; 
    botaodireita.style.visibility = 'hidden'; 
  } else {
    botaoesquerda.style.visibility = 'visible'; 
    botaodireita.style.visibility = 'visible'; 
  }
  }
  var pagina = 1;
  var created = false
  function Passar(){
    const ContainerIntro = document.getElementById('intro')
    const ContainerContato = document.getElementById('contato')
    const ContainerCalendario = document.getElementById('calendario')
    const ContainerFinal = document.getElementById('Final')
    const Carrossel = document.getElementById('Carrossel')
    const Nome = document.getElementById('Nome')
    const Numero = document.getElementById('Numero')
    const Email = document.getElementById('Email')
    const Insta = document.getElementById('Insta')
    const Logo = document.getElementById('Logo')
    const luciana = document.getElementsByClassName('luciana')[0]
    const largura = window.innerWidth;
    if(pagina === 1){
      if (Nome.value === ''){
        Nome.style.borderColor = 'red'
      } else {
      document.getElementById('greetings').innerText = "Olá " + Nome.value + '!'
      Nome.style.borderColor = 'black'
      Carrossel.style.transform = 'translatex(-800px)'
      Carrossel.style.top = 30 + '%'
      if (largura > 500){
      Logo.style.top = 15 + '%'
    } else {
      Logo.style.top = 5 + '%'
    }
      ContainerContato.style.transition =  'opacity ' + 0.5 +  's ease' 
      ContainerIntro.style.opacity = 0
      ContainerContato.style.opacity = 20

      pagina++}
    } else if (pagina === 2){
      if(NumeroInvalido === true||Insta.value === '' || emailnaovalido === true){
        if(Numero.value.length < 1){
          Numero.style.borderColor = 'red'
        }
        if (emailnaovalido === true){
          Email.style.borderColor = 'red'
        }
        if (Insta.value === ''){
          Insta.style.borderColor = 'red'
        }
      } else{
      document.getElementById('dias').scrollLeft = -200
      Numero.style.borderColor = 'black'
      Email.style.borderColor = 'black'
      Insta.style.borderColor = 'black'
      Logo.style.top = 5 + '%'
      ContainerContato.style.visibility = 'hidden'
      ContainerCalendario.style.opacity = 20
      ContainerCalendario.style.visibility = 'visible'
      if (largura > 500){
      Carrossel.style.transform = 'translatex(-1300px)'
      Carrossel.style.top = 15+ '%'
      luciana.style.left = '2000px'}
      else{
      Carrossel.style.transform = 'translatex(-1615px)'
      Carrossel.style.top = 10 + '%'
      }
      if (created === false){
      criarbotaodia()
      created = true}
      pagina++}
    } else if (pagina === 3){
      const HoraSelecionada = document.querySelector('input[name = "horario"]:checked').id
      salvarsupabase()
      salvarSheets()
      salvarAgenda()
      setTimeout(() =>{ Mensagem( Numero.value,Nome.value, HoraSelecionada)}, 5000)
      console.log(HoraSelecionada)
      ContainerCalendario.style.opacity = 0
      setTimeout(() => {
        Carrossel.style.display = 'none';
      }, 500); // 2000 milissegundos = 2 segundos
      luciana.style.left = '55%'
      ContainerFinal.style.visibility = 'visible'
      ContainerFinal.style.opacity = 20
    }
  }
  function Voltar(){
    const ContainerIntro = document.getElementById('intro')
    const ContainerContato = document.getElementById('contato')
    const ContainerCalendario = document.getElementById('calendario')
    const Carrossel = document.getElementById('Carrossel')
    const luciana = document.getElementsByClassName('luciana')[0]
    const Logo = document.getElementById('Logo')
    if(pagina === 2){
      Carrossel.style.transform = 'translatex(0px)'
      Carrossel.style.top = 40 + '%'
      ContainerIntro.style.opacity = 20
      ContainerContato.style.transition = 'none'
      ContainerContato.style.opacity = 0 
      Logo.style.top = 25 + '%' 
      pagina--
    } else if(pagina === 3){
      ContainerContato.style.visibility = 'visible'

      ContainerCalendario.style.opacity = 0
      ContainerCalendario.style.visibility = 'hidden'
      Carrossel.style.transform = 'translatex(-800px)'
      Carrossel.style.top = 30+ '%'
      luciana.style.left = '55%'

      pagina --
    }
  }
  return (
    <div className="App">
      <img src={Fotos['Logo']} className='Logo' id='Logo' alt='Logo'></img> 
      <img src={Fotos['Flor']} className='flor' id='flor' alt='flor'></img> 
      <div id='Carrossel' className='Carrossel' >
        <div id='intro' className='setor' >
          <h1>Olá</h1>
          <h3>Agradecemos o interesse, precisamos apenas de algumas informações para marcarmos nossa consulta</h3>
          <input type='text' id='Nome' className='caixaderesposta' placeholder=' Nome'></input>
          <div className='botot'>
          <input type='button' value='Próximo' className='botoesbt' id='prosseguir'  onClick={Passar}></input>
          </div>
        </div>
        <div id='contato' className='setor' style={{opacity: 0}}>
          <h1 id='greetings'></h1>
          <h3>Agora nos informe seus dados de contato:</h3>
          <label className='label2' htmlFor='Numero'>Numero:</label><br></br>
          <input type='tel' id='Numero' maxLength={15} onInput={arrumarnumero} className='caixaderesposta'></input><br></br>
          <label className='label2'  htmlFor='Email'>Email:</label><br></br>
          <input type='email' onChange={validaremail} id='Email' className='caixaderesposta'></input><br></br>
          <label className='label2' htmlFor='Insta'>Insta</label><br></br>
          <input type='text' id='Insta' onInput={arrumarinsta} className='caixaderesposta' placeholder='@'></input><br></br>
          <div className='botot'>
          <input type='button' value='Próximo'  id='prosseguir' className='botoesbt' onClick={Passar}></input>
          <input type='button' value='Voltar'  id='voltar' className='botoesvt' onClick={Voltar}></input>
          </div>
        </div>
        <div id='calendario' className='setorC' style={{opacity:0}}>
          <h1 className='noborda1'>Perfeito!</h1>
          <h3 className='noborda'>Agora selecione o horario que melhor se encaixa na sua agenda</h3>
          <div className='calendarioebts'>
              <div className='calendario'>
                <div className='dias' id='dias' onScroll={Scroll}></div>
                <div className='horarios' id='horarios'></div>
              </div>
          </div>          
          <div className='botot'>
            <input type='button' value='Próximo'  id='prosseguir' className='botoesbt' onClick={Passar}></input>
            <input type='button' value='Voltar'  id='voltar' className='botoesvt' onClick={Voltar}></input>
          </div>
        </div>
      </div>
      <div id='Final'  className='Final' >
          <h1>Obrigada pelo contato</h1>
          <h3>Agora fique atento no celular que em breve uma de nossas atendentes entrará em contato</h3>
      </div>
      <img src={luciana} alt='luciana' className='luciana'></img>
    </div>
  );
}

export default App;
